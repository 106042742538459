import React from "react";
import Layout from "../Components/Layout";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

const ContactPage = props => {
  const links = ["トップ", "お問い合わせ"];
  return (
    <>
      <Layout
        links={links}
        title="Contact"
        subTitle="お問い合わせ"
        seoTitle="お問い合わせ"
        seoDescrption="子供向けコースの総詳細ページです。（幼児コース、小学生コース、ベビー＆キッズコース、競泳チーム）"
        seoKeywords="幼児コース,小学生コース,ベビー＆キッズコース,競泳チーム,所沢,狭山市"
      >
        <Container>
          <Form
            name="ホームページお問い合わせ"
            method="POST"
            data-netlify="true"
            class="contact-form"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type="email"
                name="email"
                required="true"
                placeholder="name@example.com"
              />
            </Form.Group>

            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>お問い合わせ内容</Form.Label>
              <Form.Control as="textarea" required="true" name="content" rows={9} />
              
            </Form.Group>

            <Button variant="primary" type="submit">
              送信
            </Button>
          </Form>
          <br />
        </Container>
      </Layout>
    </>
  );
};

export default ContactPage;
